import React from "react"

import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Navigator } from "../components/LanguageHelpers/Navigator"
import Layout from "../components/layout"
import PageStartBanner from "../components/PageStartBanner"

const BlogPage = ({
  pageContext,
  data: { blogPage, allBlogPost, miscString },
}) => {
  return (
    <Layout
      pageData={pageContext}
      seoTitle={blogPage.seo?.seoTitle}
      seoDescription={blogPage.seo?.seoDescription}
      seoImage={blogPage.seo?.image?.seoImageUrl}
    >
      {" "}
      <PageStartBanner
        tag={blogPage.head[0].tag}
        title={blogPage.head[0].title}
      />
      <div className="mx-auto grid w-full max-w-screen-xl grid-cols-1 gap-8 p-4 md:grid-cols-2">
        {allBlogPost.nodes.map(
          post =>
            post.summary && (
              <Navigator key={post.id} recordId={post.id}>
                <div className="justfiy-start flex h-full flex-1 flex-col items-start rounded-xl bg-lightGray shadow-xl hover:opacity-90">
                  {post.image?.gatsbyImageData && (
                    <GatsbyImage
                      alt=""
                      className="w-full"
                      image={post.image.gatsbyImageData}
                    />
                  )}
                  <div className="flex h-full w-full flex-1  flex-col items-start justify-between gap-4 p-4 ">
                    <div>
                      <h2 className="text-2xl font-semibold text-primary-600">
                        {post.title}
                      </h2>
                      <p>{post.summary}</p>
                    </div>{" "}
                    <Navigator
                      recordId={post.id}
                      className="my-4 font-semibold uppercase text-primary-600 underline"
                    >
                      {miscString.nodes[0].readMore}
                    </Navigator>
                  </div>
                </div>
              </Navigator>
            )
        )}
      </div>
    </Layout>
  )
}

export default BlogPage

export const query = graphql`
  query BlogPageQuery($locale: String!) {
    blogPage: datoCmsBlogPage(locale: { eq: $locale }) {
      title
      locale
      slug
      head {
        title
        tag
      }
      seo {
        seoTitle: title
        seoDescription: description
        seoImage: image {
          seoImageUrl: url
        }
      }
    }
    allBlogPost: allDatoCmsBlogPost(filter: { locale: { eq: $locale } }) {
      nodes {
        title
        id: originalId
        slug
        summary
        image {
          gatsbyImageData(height: 190)
        }
        content {
          value
        }
      }
    }
    miscString: allDatoCmsMiscString(filter: { locale: { eq: $locale } }) {
      nodes {
        readMore
      }
    }
  }
`
